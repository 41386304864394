.tab-bar-drawer .ant-drawer-content{
    border-radius: 16px 16px 0 0    ;
}
.tab-bar-drawer .ant-drawer-close{
    padding: 16px 8px 8px 8px !important;
}

.tab-bar-drawer .ant-list-bordered{
    border: 0;
}

.tab-bar-drawer li.ant-list-item:hover {
    background: #f8f8f8;
}

.tab-bar-drawer .adm-tab-bar-item-title-with-icon {
    cursor: pointer;
}

.adm-tab-bar.tab-bar {
    position: fixed;
    z-index: +999;
    bottom: 0;
    width: 100%;
    background: white;
    -webkit-box-shadow: 0 -2px 4px 1px #f0f0f0;
    box-shadow: 0 -2px 4px 1px #f0f0f0;
  }